import "./RegisterTab.scss";
import { useCallback, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import Input from "../components/Input";
import useAuthenticator from "../features/user/authentication";
import { signUpStateSelector } from "../features/user/authenticationSlice";
import Checkbox from "../components/Checkbox";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

interface RegisterFormInput {
    recaptchaValue?: string;
    fullName: string;
    email: string;
    company?: string;

    password: string;
    passwordConfirmation: string;

    acceptsTerms: boolean;
}

export const RegisterTab = () => {
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const { control, handleSubmit, formState: { errors }, setValue, getValues, setError } = useForm<RegisterFormInput>({
        defaultValues: {
            acceptsTerms: false,
            company: "",
            email: "",
            fullName: "",
            password: "",
            passwordConfirmation: "",
        },
    });

    const { status: signUpStatus, error: signUpError } = useSelector(signUpStateSelector);
    const navigate = useNavigate();
    const { signUp } = useAuthenticator();

    useEffect(() => {
        if (signUpError?.code === "UserLambdaValidationException") {
            recaptchaRef.current?.reset();
        }
    }, [signUpError?.code]);

    const onRecaptchaTokenChange = useCallback((token: string | null) => {
        if (token) {
            setValue("recaptchaValue", token);
        }
    }, []);

    const onSubmit = useCallback((inputs: RegisterFormInput) => {
        if (inputs.recaptchaValue === "" || !inputs.recaptchaValue) {
            setError("recaptchaValue", {
                message: "You must complete the reCAPTCHA before registering!",
                type: "value",
            });
            return;
        }

        signUp({
            password: inputs.password,
            email: inputs.email,
            fullName: inputs.fullName,
            company: inputs.company,
            recaptchaCode: inputs.recaptchaValue,
        });
    }, [signUp]);

    if (signUpStatus === "success") {
        return (
            <div style={{ textAlign: "center" }}>
                <p>🎉 Registration successful 🎉</p>
                <p><b>Check your inbox</b> to confirm your email address in order to use the full features of your account.</p>
            </div>
        )
    }

    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="disclaimer danger">
                Please use the email address that you used to purchase a license. This may be different from your Discord email address.
            </div>
            <div className="input-group">
                <label>E-mail<span className="danger">*</span></label>
                <Controller
                    name="email"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Type your e-mail..." error={errors.email !== undefined} disabled={signUpStatus === "pending"} />} />
                {errors.email?.type === "required" &&
                    <p className="danger">The e-mail is required.</p>}
            </div>

            <div className="input-group">
                <label>Full name<span className="danger">*</span></label>
                <Controller
                    name="fullName"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Type your full name..." error={errors.fullName !== undefined} disabled={signUpStatus === "pending"} />} />
                {errors.fullName?.type === "required" &&
                    <p className="danger">The full name is required.</p>}
            </div>

            <div className="input-group">
                <label>Password<span className="danger">*</span></label>
                <Controller
                    name="password"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"password"} placeholder="Type your password..." error={errors.password !== undefined} disabled={signUpStatus === "pending"} />} />
                {/* {errors.password?.type === "required" &&
                    <p className="danger">The password is required.</p>}
                {signUpStatus === "error" && signUpError?.code === "InvalidPasswordException" &&
                    <p className="danger">{signUpError?.message}</p>} */}
            </div>
            <div className="input-group">
                <label>Password Confirmation<span className="danger">*</span></label>
                <Controller
                    name="passwordConfirmation"
                    rules={{
                        required: true,
                        validate: (password) => {
                            if (getValues().password === password) return true;

                            return "Passwords do not match.";
                        }
                    }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"password"} placeholder="Type your password again..." error={errors.passwordConfirmation !== undefined} disabled={signUpStatus === "pending"} />} />
                {errors.passwordConfirmation?.type === "validate" &&
                    <p className="danger">{errors.passwordConfirmation.message}</p>}
                {errors.passwordConfirmation?.type === "required" &&
                    <p className="danger">The password confirmation is required.</p>}
            </div>

            <div className="input-group">
                <label>Company</label>
                <Controller
                    name="company"
                    rules={{ required: false, maxLength: 75 }}
                    control={control}
                    render={({ field }) => <Input {...field} type={"text"} placeholder="Type your company name..." error={errors.company !== undefined} disabled={signUpStatus === "pending"} />} />
                {errors.company?.type === "maxLength" &&
                    <p className="danger">The company name cannot have more than 75 characters.</p>}
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Controller
                    name="acceptsTerms"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { name, value, onChange } }) => <Checkbox name={name} checked={value} onChange={onChange} error={errors.acceptsTerms !== undefined} disabled={signUpStatus === "pending"} />} />
                <span style={{ marginRight: "5px" }} />
                <label onClick={_ => setValue("acceptsTerms", !getValues().acceptsTerms)} style={{ cursor: "pointer" }}>I accept the terms and conditions.<span className="danger">*</span></label>
            </div>

            <div className="input-group">
                {/* {signUpStatus === "error" && signUpError?.code === "Unknown" &&
                    <p className="danger">{signUpError?.message}</p>} */}
                <Button type="submit" color={"primary"} disabled={signUpStatus === "pending"}>
                    {signUpStatus === "pending" && <span>Signing up...</span>}
                    {signUpStatus !== "pending" && <span>Sign Up</span>}
                </Button>
            </div>
            <div className="d-flex align-items-center">
                <ReCAPTCHA ref={recaptchaRef} sitekey="6Le_Y10oAAAAAEBX7PiHT_hV-oahOwXXkLA18Xtd" onChange={onRecaptchaTokenChange} theme="dark" style={{ marginLeft: "auto", marginRight: "auto" }} />
            </div>
            <div>
                {signUpStatus === "error" && <p className="danger">{signUpError?.message}</p>}
            </div>
        </form>
    )
}