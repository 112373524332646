import "./UpgradeToSuiteCard.scss";
import { useDispatch, useSelector } from "react-redux";
import { Subscription, SubscriptionChangeType, cancelPendingChange, cancelStatusStateSelector, initSuiteUpgrade, suiteUpgradeStateSelector } from "../../features/user/subscriptionsSlice";
import iconBillPaper from "../../assets/media/icons/bill_paper.svg";
import { useEffect, useMemo } from "react";
import Button from "../Button";

export interface UpgradeToSuiteCardProps {
    sub?: Subscription;
}

export const UpgradeToSuiteCard = ({ sub }: UpgradeToSuiteCardProps) => {
    if (!sub || !sub.isActive || sub.productCode.includes("jangafx-suite")) return <></>;

    return (
        <div className="card">
            <div className="header">
                <img src={iconBillPaper} />
                <h4 className="muted">Upgrade</h4>
            </div>
            <div id="upgradeToSuiteCardContent">
                {sub.pendingChange?.changeType === SubscriptionChangeType.SuiteUpgrade &&
                    <p className="mb-1">
                        <span className="danger bold">NOTE:</span> You will need to deactivate your old EmberGen key and activate your new suite key.
                    </p>}
                {sub.pendingChange?.changeType !== SubscriptionChangeType.SuiteUpgrade &&
                    <p className="mb-1">Upgrade now and get access to all current and future software made by us.</p>}
                <UpgradeToSuiteButton sub={sub} />
            </div>
        </div>
    )
}

interface UpgradeToSuiteButtonProps {
    sub?: Subscription;
}
export const UpgradeToSuiteButton = ({ sub }: UpgradeToSuiteButtonProps) => {
    const dispatch = useDispatch();
    const initSuiteUpgradeState = useSelector(suiteUpgradeStateSelector(sub?.id));
    const cancelChangeState = useSelector(cancelStatusStateSelector(sub?.id));

    useEffect(() => {
        if (initSuiteUpgradeState?.status.value === "success") {
            window.open(initSuiteUpgradeState.data?.quoteUrl, "_blank");
        }
    }, [initSuiteUpgradeState?.status]);

    if (!sub || !sub.isActive || sub.productCode.includes("jangafx-suite")) return <></>;

    const isIndie = useMemo(() => sub.productCode.includes("indie"), [sub.productCode]);
    if (initSuiteUpgradeState?.status.value === "pending") {
        return <Button disabled color={"danger"} style={{ width: "100%" }}>Generating quote...</Button>
    }

    if (sub.pendingChange?.changeType === SubscriptionChangeType.SuiteUpgrade) {
        return (
            <div className="row">
                <button disabled={cancelChangeState?.value === "pending"} className="btn success col-6" onClick={() => window.open(sub.pendingChange?.quoteUrl, "_blank")}>Pay quote to upgrade</button>
                <button disabled={cancelChangeState?.value === "pending"} className="btn danger col-6" onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))}>Cancel quote</button>
            </div>
        );
    }

    if (isIndie) {
        return (
            <button className="btn primary w-100" style={{ backgroundColor: "#FB8801" }}
                onClick={() => dispatch(initSuiteUpgrade({ subId: sub.id }))}>
                Upgrade to Suite for $100
            </button>
        )
    } else {
        return <Button color={"primary"} style={{ width: "100%" }}
            onClick={() => dispatch(initSuiteUpgrade({ subId: sub.id }))}>
            Upgrade to Suite
        </Button>
    }
};